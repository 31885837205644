import './App.scss';
import Welcome from 'components/Welcome';

function App() {
  return (
    <div className="App">
         <Welcome />
      {/* <div className="footer">
      </div> */}
    </div>
  );
}

export default App;
